console.log('vendor.js loaded');
// vendors
import "jquery";
headerFixed();
function headerFixed() {
    var header = $("#header");
    var scroll = $(window).scrollTop();
    if (scroll >= 10) {
        header.removeClass('header').addClass("header-fixed");
    } else {
        header.removeClass("header-fixed").addClass('header');
    }
}
$(document).ready(function () {
    $(window).scroll(function () {
        headerFixed();
    });
});
//import './app/jquery-ui-1.10.3.custom.min.js';
//import './app/jquery.hoverIntent.r7';
//import './app/jquery.cycle.all.js';
//import './app/swiper.min.js';
